import "./App.css";

import styled from "styled-components";

const Container = styled.div`
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
`;

const Week = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  margin-right: 3px;
  margin-bottom: 3px;
  display: inline-block;
`;

const TimeSeperator = styled.div`
  height: 24px;
  width: 4px;
  border-radius 2px;
  background: rgba(0,0,0,0.2);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  margin-top:6px
`;

const SubSectionParagraph = styled.p`
  margin: 0;
`;

function weeksBetween(d1, d2) {
  console.log(d1, d2);
  return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
}

const SHELLDATE = new Date("1988-01-01T00:00:00");
const CLIMATEAGREEMENT = new Date("2015-12-12T00:00:00");
const ELECTIONS = new Date("2021-04-15T00:00:00");
const CLIMATEGOAL = new Date("2030-01-01T00:00:00");

function App() {
  return (
    <Container>
      <p>
        <h1>⏰ De tijd tikt.</h1>
        <h3>
          De verkiezingen van deze week zijn de verkiezingen van het klimaat.
        </h3>
        <br />
        <h3>Scroll om te zien hoeveel tijd we nog hebben ⬇️</h3>
      </p>
      <p>
        <br />
        <h3>
          🛢 Shell wist in 1988 al van het effect dat zij hadden op het klimaat.
          Ze zwegen hierover, maar hielden al wel rekening met
          zeespiegelstijging in hun handelen naar winst.
        </h3>
        <a href="https://www.volkskrant.nl/nieuws-achtergrond/shell-weet-al-decennia-van-klimaatverandering-gaat-de-rechter-de-oliereus-dwingen-te-verduurzamen~b61ee073/">
          Lees meer &raquo;
        </a>
        <br />
      </p>
      <p style={{ textAlign: "center" }}>
        <h2>
          <Week /> = 1 week
        </h2>
      </p>
      <h3>1988</h3>
      <TimeSeperator />

      <section>
        {new Array(weeksBetween(SHELLDATE, CLIMATEAGREEMENT))
          .fill(1)
          .map(() => (
            <Week />
          ))}
      </section>
      <TimeSeperator />
      <SubSectionParagraph>
        <h2>🤝 2015: Klimaatakkoord parijs.</h2>
        <h3>
          Toezeggingen worden gedaan om onomkeerbare gevolgen door
          klimaatverandering te stoppen. De VVD haalt deze doelen niet.
        </h3>
        <a href="https://www.ad.nl/politiek/groenlinks-vvd-breekt-met-klimaatafspraken~aa9d4cd4/">
          Lees meer &raquo;
        </a>
      </SubSectionParagraph>
      <TimeSeperator />
      <section>
        {new Array(weeksBetween(CLIMATEAGREEMENT, ELECTIONS))
          .fill(1)
          .map(() => (
            <Week />
          ))}
      </section>
      <TimeSeperator />

      <SubSectionParagraph>
        <h2>🗳 Verkiezingen 2021</h2>
        <h3>We staan nu hier.</h3>
      </SubSectionParagraph>
      <TimeSeperator />

      <section>
        {new Array(weeksBetween(ELECTIONS, CLIMATEGOAL)).fill(1).map(() => (
          <Week style={{ backgroundColor: "green" }} />
        ))}
      </section>

      <TimeSeperator />

      <SubSectionParagraph>
        <h2>🌍 Klimaatdoelstelling 2030</h2>
        <h3>
          Deze doelstellingen moeten gehaald worden om onomkeerbare gevolgen
          voor onze planeet te voorkomen. Het programma van de VVD haalt deze
          niet.
        </h3>
      </SubSectionParagraph>
      <TimeSeperator />

      <p>
        <h3>
          We zijn nog niet te laat, maar het is nu tijd om actie te ondernemen.
        </h3>
      </p>

      <p>
        <h3>
          Laat je stem horen op 15, 16 en 17 maart. Stem groen en stem
          progressief.
        </h3>
      </p>

      <h3>
        <a href="https://groenlinks.nl">Groenlinks 🌍</a>
      </h3>
      <h3>
        <a href="https://www.partijvoordedieren.nl/">
          Partij voor de dieren 🦊
        </a>
      </h3>
      <h3>
        <a href="https://d66.nl">D66 🙋🏼‍♀️</a>
      </h3>
      <h3>
        <a href="https://bij1.org/">Bij1 🌈</a>
      </h3>
      <br />
      <p>
        <a href="https://nooitmeerrutte.nl/">
          Maar stem in ieder geval Nooit Meer Rutte.
        </a>
      </p>
      <br />
      <p>
        Made with ❤️ by <a href="https://twitter.com/nickvernij">nick</a>
      </p>
    </Container>
  );
}

export default App;
